<template>
  <v-card outlined elevation="0">
    <v-row class="pa-3">
      
      <v-toolbar dense color="transparent" elevation="0">
        <router-link :to="'/admin/create-entity/reservation/' + resa._id" style="text-decoration:none">
          <small :class="colorStatus + '--text'"><v-icon small :color="colorStatus">mdi-cart-arrow-down</v-icon> n°{{ resa._id }}</small>
        </router-link>
        <v-spacer/>
        <v-btn @click="$root.$emit('readResaDetails', { type: 'reservation', data : resa })"
                elevation="0" icon small class="mr-0"
                title="Afficher / éditer la commande">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </v-toolbar>

      <v-col cols="12" class="pt-0 px-4">

        <b>{{ resa.name }}</b> - 
        <small>{{ resa.email }} | <b>{{ resa.totalPrice }}€</b></small><br>

        <small class="cyan--text" v-if="resa.created != null">
          Créé 
          <time-ago :datetime="resa.created" style="font-size:11px !important;"
                    :refresh="60" long :locale="$vuetify.lang.current">
          </time-ago>
        </small>
        
        <small class="cyan--text" v-if="resa.updated != null">
          <span v-if="resa.created != null"> - </span>
          Modifié
          <time-ago :datetime="resa.updated" style="font-size:11px !important;"
                    :refresh="60" long :locale="$vuetify.lang.current">
          </time-ago>
        </small>

      </v-col>

    </v-row>
  </v-card>
</template>


<script>
import core from '../..//../plugins/core.js'
//import axios from 'axios'
import {TimeAgo} from "vue2-timeago"

export default {
    name: "card-resa",
    components: {
      TimeAgo
    },
    props: ['resa'],
    data: () => ({
       
    }),
    mounted: function(){
    },
    methods: {
        baseUrl(){ return core.baseUrl() }
    },
    computed:{
      colorStatus(){ 
        if(this.resa.status == 'OPEN') return 'blue'
        if(this.resa.status == 'PAID') return 'green'
        return 'grey'
      }
    }
};
</script>