<template>
<div v-if="show">
  <v-card outlined class="my-2">
    <v-col cols="12">
      <div class="mb-2">
        <v-btn color="blue" class="mx-1" outlined x-small @click="showDialog = true">
          <v-icon small class="mr-1">mdi-eye</v-icon> afficher
        </v-btn>
        <v-btn color="green" class="mx-1" outlined x-small @click="moderate(true)">
          <v-icon small>mdi-check</v-icon> accepter
        </v-btn>
        <v-btn color="red" class="mx-1" outlined x-small @click="moderate(false)">
          <v-icon small>mdi-cancel</v-icon> refuser
        </v-btn>
      </div>

      <v-row>
        <v-col cols="3">
          <v-img :src="baseUrl() + comment.product.image"/>
        </v-col>
        <v-col cols="8">
        {{ comment.authorName }}<br>
        <time-ago :datetime="comment.created" style="font-size:11px !important;"
                  :refresh="60" long :locale="$vuetify.lang.current">
        </time-ago><br>
        <small v-html="nl2br(comment.text).substr(0, 100)"></small>
        </v-col>
      </v-row>
    </v-col>
  </v-card>


  <v-dialog v-model="showDialog" max-width="550"
            :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card outlined>
      <v-col class="pb-0">
        <small v-html="nl2br(comment.text)"></small>
      </v-col>
      <v-toolbar color="transparent" elevation="0">
        <v-spacer/>
        <v-btn @click="showDialog = false" outlined class="mx-1">ok</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</div>
</template>


<script>
import core from '../../../plugins/core.js'
import axios from 'axios'
import {TimeAgo} from "vue2-timeago"

export default {
    name: "card-comment",
    components: {
      TimeAgo
    },
    props: ['comment'],
    data: () => ({
       showDialog: false,
       show: true
    }),
    mounted: function(){
    },
    methods: {
      moderate: async function(show){
        let comment = {
          authorName: this.comment.authorName,
          authorEmail: this.comment.authorEmail,
          text: this.comment.text,
          productId: this.comment.productId,
          show: show,
          moderated: true
        }
        
        const { data } = await axios.post('/admin/save-entity', 
                                { entityType: 'comment',
                                  entityId: this.comment._id, 
                                  entityData: comment,
                                  translations: []
                                })
        if(data.error == false){
          this.show = false
        }
      },
      baseUrl(){ return core.baseUrl() },
      nl2br: function(str, is_xhtml){
        return core.nl2br(str, is_xhtml)
      },
    },
    computed:{
      
    }
};
</script>