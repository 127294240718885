<template>
<div>
  
  <DashboardHeader></DashboardHeader>

  <v-row class="pb-5 mx-sm-0">
    <v-col cols="12">

      <v-divider class="my-3"/>
      
      <v-icon>mdi-comment</v-icon> <b>Commentaire à modérer</b>
      <v-btn @click="loadComments()" elevation="0" class="ml-2"
              dark fab x-small>
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>

      <v-divider class="my-3"/>
      
    </v-col>
    <template v-for="(comment, r) in comments">
      <v-col cols="12" sm="6" md="4" lg="4" :key="r" class="">
        <CardComment :comment="comment"></CardComment>
      </v-col>
    </template>
  </v-row>

  <v-row class="pb-5 mx-sm-0">
    <v-col cols="12">

      <v-divider class="my-3"/>
      
      <v-icon>mdi-cart-arrow-down</v-icon> <b>Réservations</b>
      <v-btn @click="loadResas()" elevation="0" class="ml-2"
              dark fab x-small>
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>

      <v-divider class="my-3"/>
      
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="blue">mdi-circle</v-icon> Réservé
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="green">mdi-circle</v-icon> Payé
      </v-chip>
      
    </v-col>
    <template v-for="(resa, r) in reservations">
      <v-col cols="12" sm="6" md="4" lg="3" :key="r" class="">
        <CardResaSM :resa="resa"></CardResaSM>
      </v-col>
    </template>
  </v-row>


  <v-row class="pb-5 mx-sm-0">
    <v-col cols="12">
      <v-divider class="my-3"/>
        <v-icon class="mb-1">mdi-star-outline</v-icon> <b>Commandes</b>
        <v-btn @click="loadCommandes()" elevation="0" class="ml-2"
                dark fab x-small>
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      <v-divider class="my-3"/>

      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="blue">mdi-circle</v-icon> En cours
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="teal">mdi-circle</v-icon> Acompte payé
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="green">mdi-circle</v-icon> Total payé
      </v-chip>

    </v-col>
    <template v-for="(commande, c) in commandes">
      <v-col cols="12" sm="6" md="4" lg="3" :key="c" class="">
        <CardCommandeSM :commande="commande"></CardCommandeSM>
      </v-col>
    </template>
  </v-row>

  <v-dialog v-model="showDialogDetails" max-width="700" >
      <template v-if="read.type=='reservation'">
          <CardResa :resa="read.data"></CardResa>
      </template>
      <template v-if="read.type=='commande'">
        <CardCommande :commande="read.data"></CardCommande>
      </template>
  </v-dialog>


</div>
</template>

<style></style>

<script>
import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import DashboardHeader from '@/components/admin/DashboardHeader.vue'
import CardResa from '@/components/admin/card/CardResa.vue'
import CardCommande from '@/components/admin/card/CardCommande.vue'
import CardResaSM from '@/components/admin/card/CardResaSM.vue'
import CardCommandeSM from '@/components/admin/card/CardCommandeSM.vue'
import CardComment from '@/components/admin/card/CardComment.vue'

export default {
  name: 'admin-dashboard',
  components: { DashboardHeader, CardResa, CardCommande, CardResaSM, CardCommandeSM, CardComment },
  data: () => ({
    adminList: [],
    reservations: [],
    commandes: [],
    comments: [],

    read: {
      type: null,
      data: null
    },
    showDialogDetails: false
  }),
  async mounted(){
    this.fetchData()
    this.loadMainData()

    this.$root.$off('reloadReservations').$on('reloadReservations', () => { this.loadResas() })
    this.$root.$off('reloadCommandes').$on('reloadCommandes', () => { this.loadCommandes() })

    this.$root.$off('addToChartSold')
    this.$root.$off('rebootChartSold')
    this.$root.$off('rerenderChartSold')


    this.$root.$off('readResaDetails').$on('readResaDetails', (read) => { 
      this.read = read
      this.showDialogDetails = true
    })
    this.$root.$off('closeResaDetails').$on('closeResaDetails', () => { 
      this.read = { type: null, data: null }
      this.showDialogDetails = false
    })

  },
  methods: {
    fetchData(){
      this.$store.dispatch("admin/fetchEntityTypesAvailables")
    },
    async loadMainData(){
      //let loaded = (this.commandes.length > 0 || this.reservations.length > 0)
      await this.loadResas()
      await this.loadCommandes()
      this.loadComments()

      // if(loaded)
      // this.$store.dispatch('app/openSnackbar', 
      //                       { show: true, color:'green', 
      //                         title: "Les données ont été actualisées",
      //                         text: null,
      //                         icon: 'thumbs-up' })
    },
    async loadResas(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "reservation",
                        query: { status: { '$in' : ['OPEN', 'PAID'] }  },
                        sort: 'status'
                      })
      if(res.data.error == false){
        this.reservations = res.data.entities
      }
    },
    async loadCommandes(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "commande",
                        query: { status: { '$in' : ['OPEN', 'PREPAID', 'PAID'] }  },
                        sort: 'status'
                      })
      if(res.data.error == false){
        this.commandes = res.data.entities
      }
    },
    async loadComments(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "comment",
                        query: { moderated: false },
                        sort: 'created'
                      })
      if(res.data.error == false){
        this.comments = res.data.entities
      }
    }
  },
  computed: {    
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
      '$route.path': { immediate: true, 
          async handler (val, oldVal) {
              if(window.isDev) { console.log("#MAIN -- route.name changed", val, oldVal) }
              //this.fetchData()
          }
      },
  }, 
}
</script>
