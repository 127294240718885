<template>
  <v-card outlined elevation="0">
    <v-row class="pa-3">

      <v-toolbar dense color="transparent" elevation="0">
        <router-link :to="'/admin/create-entity/commande/' + commande._id" style="text-decoration:none">
          <small :class="colorStatus + '--text'"><v-icon small :color="colorStatus">mdi-star-outline</v-icon> n°{{ commande._id }}</small>
        </router-link>
        <v-spacer/>
        <v-btn @click="$root.$emit('readResaDetails', { type: 'commande', data : commande })"
                elevation="0" icon small class="mr-0"
                title="Afficher / éditer la commande">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </v-toolbar>

      <v-col cols="12" sm="12" class="py-0 px-4">
        <b>{{ commande.name }}</b> - 
        <small>{{ commande.email }} | <b>{{ commande.price }}€</b></small><br>

        <small class="cyan--text" v-if="commande.created != null">
          Créé 
          <time-ago :datetime="commande.created" style="font-size:11px !important;"
                    :refresh="60" long :locale="$vuetify.lang.current">
          </time-ago>
        </small>
        
        <small class="cyan--text" v-if="commande.updated != null">
          <span v-if="commande.created != null"> - </span>
          Modifié
          <time-ago :datetime="commande.updated" style="font-size:11px !important;"
                    :refresh="60" long :locale="$vuetify.lang.current">
          </time-ago>
        </small>
        <v-divider class="mt-3"/>
      </v-col>

      
      <v-col cols="9" sm="9" class="mt-1">
        <b>{{ commande.support }}</b> - 
        <b>{{ commande.size }} cm</b> - 

        <template v-if="commande.colorRef == 'exists'">
          <b>{{ commande.productRef.name }}</b>
        </template>
        <template v-else>
          <b style="text-transform:capitalize">{{ commande.element }}</b>
        </template>
        
        <br>
        <b class="font-flower" style="font-size:22px;">
          {{ commande.birthday }} 
          - Chiffre
          {{ commande.chiffre }}
        </b>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>

import core from '../..//../plugins/core.js'
//import { i18n } from 'vue-lang-router';
import {TimeAgo} from "vue2-timeago"

export default {
    name: "card-commande",
    components: { TimeAgo
    },
    props: ['commande'],
    data: function () {
        return {
        };
    },
    mounted: function(){
    },
    methods: {
        async sendMailAccompte(){ 

        },
        async cancelCommande(){ 

        },
        baseUrl(){ return core.baseUrl() }
    },
    computed:{
      colorStatus(){ 
        if(this.commande.status == 'OPEN') return 'blue'
        if(this.commande.status == 'PREPAID') return 'teal'
        if(this.commande.status == 'PAID') return 'green'
        return 'grey'
      }
    }
};
</script>